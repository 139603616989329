import './App.css';
import '../src/Components/Styles/media.css'
import React, { useEffect } from 'react';

import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Logout from './Components/Pages/Logout';
import ProtectedRoute from './Components/Security/Security';
import PersistedRoute from './Components/Pages/PersistedRoute';
import Cookies from "js-cookie";


// Lazy load components
const Login = lazy(() => import('./Components/Pages/Login'));
const Layout = lazy(() => import('./Components/Layout/Layout'));
const Dashboard = lazy(() => import('./Components/Pages/Dashboard'));
const UserAdd = lazy(() => import('./Components/Pages/UserAdd'));
const UserList = lazy(() => import('./Components/Pages/UserList'));
const Area = lazy(() => import('./Components/Pages/Areaadd'));
const AreaList = lazy(() => import('./Components/Pages/AreaList'));
const MyProfile = lazy(() => import('./Components/Pages/MyProfile'));
const BatteryAdd = lazy(() => import('./Components/Pages/BatteryAdd'));
const BatteryList = lazy(() => import('./Components/Pages/BatteryList'));
const DealerLogin = lazy(() => import('./Components/Pages/DealerLogin'));
const DealerList = lazy(() => import('./Components/Pages/DealerList'));
const Customer = lazy(() => import('./Components/Pages/Customer'));
const Station = lazy(() => import('./Components/Pages/Station'));
const Stationadd = lazy(() => import('./Components/Pages/Stationadd'));
const Addcustomer = lazy(() => import('./Components/Pages/Addcustomer'));
const Subscription = lazy(() => import('./Components/Pages/Subscription'));
const Addsubscription = lazy(() => import('./Components/Pages/Addsubscription'));
const Forgotpass = lazy(() => import('./Components/Pages/Forgotpass'));
const Otp = lazy(() => import('./Components/Pages/Otp'))
const Resetpass = lazy(() => import('./Components/Pages/Resetpass'))
const VehicleRegister = lazy(() => import('./Components/Pages/VehicleRegister'));
const VehicleRegisterList = lazy(() => import('./Components/Pages/VehicleRegisterList'));
const VehicleModel = lazy(() => import('./Components/Pages/VehicleModel'));
const VehicleModelList = lazy(() => import('./Components/Pages/VehicleModelList'));
const VehicleColor = lazy(() => import('./Components/Pages/VehicleColor'));
const VehicleColorList = lazy(() => import('./Components/Pages/VehicleColorList'));
const Maintenancemode = lazy(() => import('./Components/Pages/Maintenancemode'));
const Maintenence = lazy(() => import('./Components/Pages/Maintenence'));
const NotFound = lazy(() => import('./Components/Pages/Not_found'));
const Addmaintenence = lazy(() => import('./Components/Pages/Addmaintenence'));
const Assignbatteries = lazy(() => import('./Components/Pages/Assignbatteries'));
const Vehicleassign = lazy(() => import('./Components/Pages/Vehicleassign')); 
const Stationbattery = lazy(() => import('./Components/Pages/Stationbattery')); 
const Viewvehicle = lazy(() => import('./Components/Pages/Viewvehicle')); 


function AppContent() {
  const location = useLocation();
  function getPageTitle(path) {
    if (path.includes('/useredit/')) {
      return 'User Edit';
    }
    if (path.includes('/batteryedit')) {
      return 'Battery Edit';
    }
    if (path.includes('/dealeredit')) {
      return 'Dealer Edit';
    }
    if (path.includes('/editcustomer')) {
      return 'Edit Customer';
    }
    if (path.includes('/editsubscription')) {
      return 'Edit Subscription';
    }
    if (path.includes('/areaedit')) {
      return 'Edit Area';
    }
    if (path.includes('/edit')) {
      return 'Edit Station';
    }

    if (path.includes('/Editmaintenence')) {
      return 'Edit Maintenence';
    }
    if (path.includes('/vehicleregisteredit')) {
      return 'Edit Vehicle Register ';
    }
    if (path.includes('/vehiclemodeledit')) {
      return 'Edit Vehicle Model';
    }
    if (path.includes('/vehiclecoloredit')) {
      return ' Edit Vehicle Color';
    }
    if (path.includes('/assignbatteries')) {
      return ' Dealer Batteries';
    }


    if (path.includes('/vehicleassign')) {
      return ' Vehicle Assign';
    }


    if (path.includes('/Stationbattery')) {
      return ' Station Batteries';
    }



    switch (path) {
      case '/dashboard':
        return 'Dashboard';

      // user
      case '/useradd':
        return 'User Add';
      case '/userlist':
        return 'User List';
      case '/useredit/:id':
        return 'User Edit';

      case '/customermanagement':
        return 'Customer List';
      case '/addcustomer':
        return 'Custmer Add';
      case '/station':
        return 'Station List';
      case '/editcustomer':
        return 'Edit Customer';


      // area
      case '/areaadd':
        return 'Area Add';
      case '/arealist':
        return 'Area List';
      case '/areaedit':
        return 'Area Edit';

      // myprofile
      case '/myprofile':
        return 'My Profile';

      // battery
      case '/batteryadd':
        return 'Battery Add';
      case '/batterylist':
        return 'Battery List';
      case '/batteryedit/:id':
        return 'Battery Edit';

      // Dealer
      case '/dealerlogin':
        return 'Dealer Add';
      case '/dealerlist':
        return 'Dealer List';
      case '/dealeredit':
        return 'Dealer Edit';

      case '/subscription':
        return 'subscription list';
      case '/addsubscription':
        return 'subscription Add';
      case '/editsubscription':
        return 'Edit subscription ';
      case '/edit':
        return 'Edit station';

      // vehicle register
      case '/vehicleregister':
        return 'Vehicle Register';
      case '/vehicleregisterlist':
        return 'Vehicle Register list'

      // vehicle model
      case '/vehiclemodel':
        return 'Vehicle Model';
      case '/vehiclemodellist':
        return 'Vehicle Model List'

      // vehicle color
      case '/vehiclecolor':
        return 'Vehicle Color'
      case '/vehiclecolorlist':
        return 'Vehicle Color List'

      case '/maintenence':
        return 'Maintenence List'
      case '/Addmaintenence':
        return ' Add Maintenence '
      case '/Editmaintenence':
        return ' Edit Maintenence '

      //station
      case '/Stationadd':
        return ' Station Add';

        case '/Viewvehicle':
          return 'View Vehicle List';

      default:
        return 'Users';
    }
  };




  return (
    <Suspense fallback={<div className="loader">Loading...</div>}>
      <Routes>
        <Route path="/" element={<Login />} />
        {/*forgatepass*/}
        <Route path="forgotpass" element={<Forgotpass />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/resetpass" element={<Resetpass />} />
        <Route path="Maintenancemode" element={<Maintenancemode />} />
        {/* NotFound */}
        <Route path="*" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout pageTitle={getPageTitle(location.pathname)} />}>

            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/dashboard" element={<PersistedRoute element={<Dashboard />} />} />
            {/* user */}
            <Route path="/useradd" element={<PersistedRoute element={<UserAdd />} />} />
            <Route path="/useredit/:id" element={<PersistedRoute element={<UserAdd />} />} />
            <Route path="/userlist" element={<PersistedRoute element={<UserList />} />} />
            {/* area */}
            <Route path="/areaadd" element={<PersistedRoute element={<Area />} />} />
            <Route path="/areaedit/:id" element={<PersistedRoute element={<Area />} />} />
            <Route path="/arealist" element={<PersistedRoute element={<AreaList />} />} />
            {/* MyProfile */}
            <Route path="/myprofile" element={<PersistedRoute element={<MyProfile />} />} />
            {/* battery */}
            <Route path="/batteryadd" element={<PersistedRoute element={<BatteryAdd />} />} />
            <Route path="/batterylist" element={<PersistedRoute element={<BatteryList />} />} />
            <Route path="/batteryedit/:id" element={<PersistedRoute element={<BatteryAdd />} />} />
            {/* Dealer */}
            <Route path='/dealerlogin' element={<PersistedRoute element={<DealerLogin />} />} />
            <Route path='/dealeredit/:id' element={<PersistedRoute element={<DealerLogin />} />} />
            <Route path='/dealerlist' element={<PersistedRoute element={<DealerList />} />} />
            {/* Logout */}
            <Route path='/logout' element={<PersistedRoute element={<Logout />} />} />
            <Route path='/customermanagement' element={<PersistedRoute element={<Customer />} />} />
            <Route path='/station' element={<PersistedRoute element={<Station />} />} />
            <Route path='/Stationadd' element={<PersistedRoute element={<Stationadd />} />} />
            <Route path="/editstation/:id" element={<PersistedRoute element={<Stationadd />} />} />
            <Route path="/addcustomer" element={<PersistedRoute element={<Addcustomer />} />} />
            <Route path="/editcustomer/:id" element={<PersistedRoute element={<Addcustomer />} />} />

            {/* subscription */}
            <Route path='/subscription' element={<PersistedRoute element={<Subscription />} />} />
            <Route path='/addsubscription' element={<PersistedRoute element={<Addsubscription />} />} />
            <Route path='/editsubscription/:id' element={<PersistedRoute element={<Addsubscription />} />} />

            {/* vehicle Register */}
            <Route path='/vehicleregister' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisteredit/:id' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisteredit' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisterlist' element={<PersistedRoute element={<VehicleRegisterList />} />} />

            {/* Vehicle Model */}
            <Route path='/vehiclemodel' element={<PersistedRoute element={<VehicleModel />} />} />
            <Route path='/vehiclemodeledit/:id' element={<PersistedRoute element={<VehicleModel />} />} />
            <Route path='/vehiclemodellist' element={<PersistedRoute element={<VehicleModelList />} />} />

            {/* Vehicle Color */}
            <Route path='/vehiclecoloredit/:id' element={<PersistedRoute element={<VehicleColor />} />} />
            <Route path='/vehiclecolor' element={<PersistedRoute element={<VehicleColor />} />} />
            <Route path='/vehiclecolorlist' element={<PersistedRoute element={<VehicleColorList />} />} />
            {/* maintenence */}
            <Route path='/maintenence' element={<PersistedRoute element={<Maintenence />} />} />
            <Route path='/Addmaintenence' element={<PersistedRoute element={<Addmaintenence />} />} />
            <Route path='/Editmaintenence/:id' element={<PersistedRoute element={<Addmaintenence />} />} />
            {/* batteries */}
            <Route path='/assignbatteries' element={<PersistedRoute element={<Assignbatteries />} />} />
            <Route path='/vehicleassign/:CustomerID' element={<PersistedRoute element={<Vehicleassign />} />} />
            <Route path='/vehicleassign/' element={<PersistedRoute element={<Vehicleassign />} />} />
            <Route path='/Stationbattery' element={<PersistedRoute element={<Stationbattery />} />} />
            <Route path='/Viewvehicle' element={<PersistedRoute element={<Viewvehicle />} />} />


          </Route>
        </Route>
      </Routes>







    </Suspense>
  );
}

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </div>
  );
}

export default App;